import { InjectionToken } from '@angular/core';

export const DIGISTORE_WEBOOK_URL = new InjectionToken(
  'WebhookUrl for digistore',
);
export const COPECART_WEBOOK_URL = new InjectionToken(
  'WebhookUrl for copecart',
);
export const ZOOM_WEBOOK_URL = new InjectionToken('WebhookUrl for copecart');

export const ABLEFY_WEBHOOK_URL = new InjectionToken('WebhookUrl for ablefy');
